import React, { useContext, useState } from "react";
import AppContext from "@context/app-context";
import SideBar from "@layout/header/sidebar";
import clsx from "clsx";
import styles from "../responsive-component/styles.module.css";

// Chưa được coi là responsive component vì chưa đúng mục đihcs
export interface INotResponsiveComponent {
    PageContent?: React.ReactNode;
    error?: string | null;
}

const ResponsiveComponent = (p: INotResponsiveComponent) => {
    const { isMobile, viewHeight } = useContext(AppContext);

    return (
        <>
            {isMobile ? (
                <div style={{ height: "100%", backgroundColor: "#292929" }}>
                    <div style={{ minHeight: isMobile && "100vh" }}>
                        {p?.error && (
                            <div className="alert alert-danger mt-5">
                                {p?.error}
                            </div>
                        )}
                        {p?.PageContent}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        height: viewHeight,
                        display: "flex",
                        backgroundColor: "#292929",
                    }}
                >
                    <div style={{ height: "100%" }}>
                        <SideBar />
                    </div>

                    <div
                        style={{
                            overflowY: "auto",
                            flex: 1,
                            backgroundColor: "#292929",
                            overflowX: "hidden",
                        }}
                        className={clsx(styles.scroll)}
                    >
                        {p?.error && (
                            <div className="alert alert-danger mt-5">
                                {p?.error}
                            </div>
                        )}
                        {p?.PageContent}
                    </div>
                </div>
            )}
        </>
    );
};

export default ResponsiveComponent;
