import SEO from "@components/seo";
import Button from "@ui/button";
import TopBarFixed from "../layouts/top-bar-fixed";
import ResponsiveComponent from "../commons/responsive-component";

export async function getStaticProps() {
    return { props: { className: "template-color-1" } };
}

const ErrorPage = () => {
    return (
        <>
            <SEO pageTitle="404" />
            <TopBarFixed />
            <ResponsiveComponent
                PageContent={
                    <div style={{ height: "100%", overflowY: "auto" }}>
                        <div className="rn-not-found-area rn-section-gapTop pb--200">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-not-found-wrapper">
                                            <h2 className="large-title">404</h2>
                                            <h3 className="title">
                                                Trang Không Tồn Tại!
                                            </h3>
                                            <p>
                                                Trang bạn đang truy cập tới
                                                không tồn tại.
                                            </p>
                                            <Button path="/">
                                                Trở Về Trang Chủ
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default ErrorPage;
