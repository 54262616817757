import Anchor from "@ui/anchor";
import BurgerButton from "@ui/burger-button";
import MobileMenu from "@components/menu/mobile-menu-02";
import UserDropdown from "@components/user-dropdown";
import { useOffcanvas, useFlyoutSearch } from "@hooks";
import { useContext } from "react";
import AuthContext from "@context/app-context";
import sideMenuData from "@data/general/menu-02.json";
import Logo from "@components/logo";
import footerData from "@data/general/footer-02.json";
import { BiLogIn } from "react-icons/bi";
import {
    Divider,
    Toolbar,
    ToolbarButton,
    Button,
} from "@fluentui/react-components";
import {
    FolderArrowUp24Regular,
    AlertBadge24Regular,
} from "@fluentui/react-icons";

const BimToolbar = () => {
    return (
        <>
            <Toolbar aria-label="App Toolbar" size="large">
                <Anchor path="/project/upload-project">
                    <ToolbarButton
                        aria-label="Tạo Dự Án"
                        appearance="subtle"
                        title="Tạo Dự Án"
                        icon={<FolderArrowUp24Regular />}
                    >
                        Tạo Project
                    </ToolbarButton>
                </Anchor>

                <ToolbarButton
                    aria-label="Xem Thông Báo"
                    icon={<AlertBadge24Regular />}
                >
                    Thông Báo
                </ToolbarButton>

                <Divider vertical className="me-1 ms-1" />

                <UserDropdown />
            </Toolbar>
        </>
    );
};

export interface ITopBarProps {
    leftSidebar3dCollapsed?: boolean;
    setLeftSidebar3dCollapsed?: Function;
}

const TopBarFixed = (props?: ITopBarProps) => {
    const { offcanvas, offcanvasHandler } = useOffcanvas();

    const { profile, collapsed, setCollapsed, isMobile } =
        useContext(AuthContext);

    const toggleSidebar = (e) => {
        setCollapsed(!collapsed);
        props?.leftSidebar3dCollapsed !== undefined &&
            props?.setLeftSidebar3dCollapsed(!props?.leftSidebar3dCollapsed);
    };

    return (
        <>
            <div
                className="rn-top-bar-area justify-content-between pt-0 pb-0 ps-4 pe-4"
                style={{ height: 50, borderBottom: ".8px solid #ffffff14" }}
            >
                {isMobile ? (
                    <div className="setting-option mobile-menu-bar ml--5 d-block d-lg-none">
                        <div className="hamberger icon-box">
                            <BurgerButton onClick={offcanvasHandler} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="contact-area fixed-tmp">
                            <div
                                className=" ms-3 d-sm-none d-lg-block text-light"
                                onClick={(e) => toggleSidebar(e)}
                                style={{
                                    zIndex: Number.MAX_SAFE_INTEGER - 10,
                                    padding: collapsed && 3,
                                    border: collapsed && ".8px solid #ffffff14",
                                    borderRadius: collapsed && 8,
                                    fontSize: 30,
                                }}
                            >
                                <i
                                    className="feather feather-align-justify"
                                    role="button"
                                ></i>
                            </div>
                        </div>
                        {collapsed && (
                            <div style={{ position: "fixed", left: 65 }}>
                                <Logo logo={footerData.logo} />
                            </div>
                        )}
                    </>
                )}

                <div className="d-flex align-items-center">
                    {profile ? (
                        <>
                            <BimToolbar />
                        </>
                    ) : (
                        <Anchor path="/auth/login">
                            <Button appearance="subtle" icon={<BiLogIn />}>
                                Đăng Nhập
                            </Button>
                        </Anchor>
                    )}
                </div>
                <MobileMenu
                    menu={sideMenuData}
                    isOpen={offcanvas}
                    onClick={offcanvasHandler}
                    logo={[
                        { src: "/images/logo/logo-white.png" },
                        { src: "/images/logo/logo-dark.png" },
                    ]}
                />
            </div>
        </>
    );
};

export default TopBarFixed;
