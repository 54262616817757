import { useContext } from "react";
import AuthContext from "../../context/app-context";
import { RequestHeader } from "../../utils/request-header-builder";
import { Auth, getPath } from "../../utils/api-path";
import {
    Button,
    Menu,
    MenuTrigger,
    ToolbarButton,
    Persona,
    MenuPopover,
    MenuList,
    MenuItem,
} from "@fluentui/react-components";

const UserDropdown = () => {
    const { profile, setProfile } = useContext(AuthContext);

    const serviceLogout = async () => {
        try {
            await fetch(
                getPath(Auth.Logout),
                RequestHeader.build({
                    method: "POST",
                    withSecutityHeader: true,
                })
            );
            location.reload();
        } finally {
            setProfile(null);
        }
    };

    return (
        <>
            <Menu>
                <MenuTrigger>
                    <ToolbarButton appearance="subtle">
                        <Persona
                            primaryText={profile.username}
                            secondaryText={new Date(
                                profile.loginTime
                            ).toLocaleTimeString()}
                            presence={{ status: "available" }}
                            avatar={{
                                image: {
                                    src: "/images/favicon.png",
                                },
                            }}
                        />
                    </ToolbarButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem>Trang Cá Nhân</MenuItem>
                        <MenuItem onClick={serviceLogout}>Đăng Xuất</MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};

export default UserDropdown;
