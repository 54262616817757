import AppContext from "@context/app-context";
import { useState, useEffect, useContext } from "react";

function useSizable() {
    const { collapsed, isMedium, isLarge, windowWidth } =
        useContext(AppContext);

    const [size, setSize] = useState(windowWidth * 0.2);

    const sizeHandler = () => {
        if (collapsed === false) {
            if (isMedium) {
                setSize(windowWidth * 0.2);
            } else if (isLarge) {
                setSize(windowWidth * 0.18);
            } else {
                setSize(windowWidth * 0.15);
            }
        } else {
            setSize(55);
        }
    };

    return { size, sizeHandler };
}

export default useSizable;
