import PropTypes from "prop-types";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "@ui/offcanvas";
import Logo from "@components/logo";
import { Link } from "react-scroll";
import Anchor from "@ui/anchor";
import { useContext } from "react";
import AuthContext from "@context/app-context";

const MobileMenu = ({ isOpen, onClick, menu, logo }) => {
    const { profile } = useContext(AuthContext);

    return (
        <Offcanvas isOpen={isOpen} onClick={onClick}>
            <OffcanvasHeader onClick={onClick}>
                <Logo logo={logo} />
            </OffcanvasHeader>
            <OffcanvasBody>
                <nav>
                    <ul className="mainmenu">
                        {profile ? (
                            <>
                                {menu?.map((nav) => (
                                    <li id={nav.id} key={nav.id}>
                                        <Anchor
                                            className="nav-link smoth-animation"
                                            onClick={onClick}
                                            path={nav.path}
                                        >
                                            {nav.text}
                                        </Anchor>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <>
                                {menu
                                    ?.filter(
                                        (nav) =>
                                            !nav.hasOwnProperty("require_auth")
                                    )
                                    .map((item) => (
                                        <li id={item.id} key={item.id}>
                                            <Anchor
                                                className="nav-link smoth-animation"
                                                onClick={onClick}
                                                path={item.path}
                                            >
                                                {item.text}
                                            </Anchor>
                                        </li>
                                    ))}
                            </>
                        )}
                    </ul>
                </nav>
            </OffcanvasBody>
        </Offcanvas>
    );
};

MobileMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    menu: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string,
        })
    ),
};

export default MobileMenu;
